// requestStore.js
import { defineStore } from 'pinia'

export const useRequestStore = defineStore('request', {
  state: () => ({
    requestData: {},
    filterPaths: [
      'manufacturer',
      'mainTypeGroupFb',
      'structureType',
      'fuelMethod',
      'constructionYear',
      'engine',
      'subType'
    ],
    responseData: {},
    responseDataChecke: false,
    requestError: null,
    loading: false
  }),

  actions: {
    transformData(tradeInStore) {
      const activeAccordion = tradeInStore.getDisplayedAccordion()
      const requestData = {
        clientId: tradeInStore.clientId,
        ecode: tradeInStore.vehicles[0].ecode,
        vin: null,
        hsn: null,
        tsn: null,
        manufacturerName: tradeInStore.vehicles[0].manufacturerName,
        modelName: tradeInStore.vehicles[0].mainTypeName,
        containerName: tradeInStore.vehicles[0].containerName,
        exFactoryPrice: tradeInStore.vehicles[0].exFactoryPrice,
        classifyingEquipments: Object.values(tradeInStore.vehicles[0].equipments),
        describingEquipments: Object.values(tradeInStore.vehicles[0].equipmentsSuper),

        filters: this.filterPaths.reduce((acc, filter) => {
          let step
          if (filter === 'constructionYear' || filter === 'engine' || filter === 'subType') {
            step = tradeInStore.accordion['accordion2'].steps[filter]
          } else {
            step = tradeInStore.accordion['accordion1'].steps[filter]
          }
          if (step && step.selectedProposal && step.selectedProposal.k) {
            acc.push({
              filter,
              title: step.title,
              value: step.selectedProposal.k,
              label: step.selectedProposal.v
            })
          }
          return acc
        }, [])
      }
      if (tradeInStore.activeTab === 'hersteller') {
        ;(requestData.specialEquipments = Object.fromEntries(
          Object.entries(
            activeAccordion['accordion3'].steps.specialEquipments.selectedProposal
          ).map(([key, value]) => [key, value.name])
        )),
          (requestData.initialRegistration =
            activeAccordion[
              'accordion4'
            ].steps.carRegistration.selectedProposal.initialRegistration)
        requestData.mileage =
          activeAccordion['accordion4'].steps.carRegistration.selectedProposal.milage
        requestData.numberOfPreviousOwners =
          activeAccordion['accordion4'].steps.carRegistration.selectedProposal.numberOfPreviousOwner
        requestData.color =
          activeAccordion['accordion4'].steps.carRegistration.selectedProposal.color
        requestData.accidents = activeAccordion['accordion5'].steps.accident.selectedProposal
          .accident
          ? activeAccordion['accordion5'].steps.accident.selectedProposal.accidentDescription
          : null
        requestData.damages = activeAccordion['accordion5'].steps.damage.selectedProposal.damage
          ? activeAccordion['accordion5'].steps.damage.selectedProposal.damageDescription
          : null
        requestData.saleType = activeAccordion['accordion6'].steps.price.selectedProposal.saleType
        requestData.financing =
          activeAccordion['accordion6'].steps.finance.selectedProposal.financing
        requestData.vehicleLetterAtBank =
          activeAccordion['accordion6'].steps.finance.selectedProposal.vehicleLetterAtBank
        requestData.priceRequest =
          activeAccordion['accordion6'].steps.price.selectedProposal.priceRequest
        requestData.firstName =
          activeAccordion['accordion7'].steps.submit.selectedProposal.firstName
        requestData.lastName = activeAccordion['accordion7'].steps.submit.selectedProposal.lastName
        requestData.email = activeAccordion['accordion7'].steps.submit.selectedProposal.email
        requestData.phone = activeAccordion['accordion7'].steps.submit.selectedProposal.phone || ''
        requestData.message =
          activeAccordion['accordion7'].steps.submit.selectedProposal.message || ''
      } else if (tradeInStore.activeTab === 'hsnTsn') {
        requestData.hsn = activeAccordion['accordion1'].steps.hsnTsn.selectedProposal.hsn
        requestData.tsn = activeAccordion['accordion1'].steps.hsnTsn.selectedProposal.tsn
        ;(requestData.specialEquipments = Object.fromEntries(
          Object.entries(
            activeAccordion['accordion2'].steps.specialEquipments.selectedProposal
          ).map(([key, value]) => [key, value.name])
        )),
          (requestData.initialRegistration =
            activeAccordion[
              'accordion3'
            ].steps.carRegistration.selectedProposal.initialRegistration)
        requestData.mileage =
          activeAccordion['accordion3'].steps.carRegistration.selectedProposal.milage
        requestData.numberOfPreviousOwners =
          activeAccordion['accordion3'].steps.carRegistration.selectedProposal.numberOfPreviousOwner
        requestData.color =
          activeAccordion['accordion3'].steps.carRegistration.selectedProposal.color
        requestData.accidents = activeAccordion['accordion4'].steps.accident.selectedProposal
          .accident
          ? activeAccordion['accordion4'].steps.accident.selectedProposal.accidentDescription
          : null
        requestData.damages = activeAccordion['accordion4'].steps.damage.selectedProposal.damage
          ? activeAccordion['accordion4'].steps.damage.selectedProposal.damageDescription
          : null
        requestData.saleType = activeAccordion['accordion5'].steps.price.selectedProposal.saleType
        requestData.financing =
          activeAccordion['accordion5'].steps.finance.selectedProposal.financing
        requestData.vehicleLetterAtBank =
          activeAccordion['accordion5'].steps.finance.selectedProposal.vehicleLetterAtBank
        requestData.priceRequest =
          activeAccordion['accordion5'].steps.price.selectedProposal.priceRequest
        requestData.firstName =
          activeAccordion['accordion6'].steps.submit.selectedProposal.firstName
        requestData.lastName = activeAccordion['accordion6'].steps.submit.selectedProposal.lastName
        requestData.email = activeAccordion['accordion6'].steps.submit.selectedProposal.email
        requestData.phone = activeAccordion['accordion6'].steps.submit.selectedProposal.phone || ''
        requestData.message =
          activeAccordion['accordion6'].steps.submit.selectedProposal.message || ''
      } else {
        requestData.vin = activeAccordion['accordion1'].steps.vin.selectedProposal.vin
        ;(requestData.specialEquipments = Object.fromEntries(
          Object.entries(
            activeAccordion['accordion2'].steps.specialEquipments.selectedProposal
          ).map(([key, value]) => [key, value.name])
        )),
          (requestData.initialRegistration =
            activeAccordion[
              'accordion3'
            ].steps.carRegistration.selectedProposal.initialRegistration)
        requestData.mileage =
          activeAccordion['accordion3'].steps.carRegistration.selectedProposal.milage
        requestData.numberOfPreviousOwners =
          activeAccordion['accordion3'].steps.carRegistration.selectedProposal.numberOfPreviousOwner
        requestData.color =
          activeAccordion['accordion3'].steps.carRegistration.selectedProposal.color
        requestData.accidents = activeAccordion['accordion4'].steps.accident.selectedProposal
          .accident
          ? activeAccordion['accordion4'].steps.accident.selectedProposal.accidentDescription
          : null
        requestData.damages = activeAccordion['accordion4'].steps.damage.selectedProposal.damage
          ? activeAccordion['accordion4'].steps.damage.selectedProposal.damageDescription
          : null
        requestData.saleType = activeAccordion['accordion5'].steps.price.selectedProposal.saleType
        requestData.financing =
          activeAccordion['accordion5'].steps.finance.selectedProposal.financing
        requestData.vehicleLetterAtBank =
          activeAccordion['accordion5'].steps.finance.selectedProposal.vehicleLetterAtBank
        requestData.priceRequest =
          activeAccordion['accordion5'].steps.price.selectedProposal.priceRequest
        requestData.firstName =
          activeAccordion['accordion6'].steps.submit.selectedProposal.firstName
        requestData.lastName = activeAccordion['accordion6'].steps.submit.selectedProposal.lastName
        requestData.email = activeAccordion['accordion6'].steps.submit.selectedProposal.email
        requestData.phone = activeAccordion['accordion6'].steps.submit.selectedProposal.phone || ''
        requestData.message =
          activeAccordion['accordion6'].steps.submit.selectedProposal.message || ''
      }

      this.requestData = requestData // <--- Set the requestData state

      return requestData
    },
    async fetchRequestData() {
      // console.log('fetchTradeInData called')
      this.loading = true
      if (!this.requestData.clientId) return
      try {
        const url = `https://tradein.cloud.audaris.icu/-/api?clientId=${this.requestData.clientId}`
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.requestData)
        })

        if (!response.ok) {
          this.handleFetchError(response.status)
          return
        }

        const data = await response.json()
        this.responseData = data
        this.responseDataChecke = true
      } catch (error) {
        // console.error('Fehler beim Fetchen der Daten:', error)
      } finally {
        this.loading = false
      }
    },

    handleFetchError(status) {
      switch (status) {
        case 429:
          this.requestError = 'Zu viele Anfragen, bitte versuche es später noch einmal'
          break
        default:
          this.requestError = `Fehler ${status}: ${this.getErrorMessage(status)}`
      }
    },

    getErrorMessage(status) {
      switch (status) {
        case 400:
          return 'Ungültige Anfrage'
        case 401:
          return 'Authentifizierung fehlgeschlagen'
        case 500:
          return 'Interner Serverfehler'
        default:
          return 'Unbekannter Fehler'
      }
    }
  }
})
