<template>
  <div v-if="currentStepPath" class="p-2">
    <div class="flex flex-wrap -mx-2">
      <!-- {{ currentStepPath }} -->
      <label class="block w-full md:w-1/2 mt-4 px-2"
        ><span class="text-sm font-semibold">Vorname *</span
        ><input
          type="text"
          class="form-input w-full mt-1"
          v-model="currentStepPath.firstName" /></label
      ><label class="block w-full md:w-1/2 mt-4 px-2"
        ><span class="text-sm font-semibold">Nachname *</span
        ><input type="text" class="form-input w-full mt-1" v-model="currentStepPath.lastName"
      /></label>
    </div>
    <div class="flex flex-wrap -mx-2">
      <label class="block w-full md:w-1/2 mt-4 px-2"
        ><span class="text-sm font-semibold"
          >E-Mail {{ tradeInStore.isEmailRequired ? '*' : '' }}</span
        ><input
          type="email"
          class="form-input w-full mt-1"
          v-model="currentStepPath.email"
          :required="tradeInStore.isEmailRequired"
          :class="{ 'required-field': tradeInStore.isPhoneRequired }"
        />
      </label>

      <label class="block w-full md:w-1/2 mt-4 px-2">
        <span class="text-sm font-semibold"
          >Telefon {{ tradeInStore.isPhoneRequired ? '*' : '' }}</span
        >
        <input
          type="phone"
          class="form-input w-full mt-1"
          v-model="currentStepPath.phone"
          :required="tradeInStore.isPhoneRequired"
        />
      </label>
    </div>
    <label class="block mt-4">
      <span class="text-sm font-semibold">Nachricht</span>
      <textarea class="form-input w-full mt-1" v-model="currentStepPath.message"></textarea>
    </label>
    <label class="block mt-4">
      <input
        id="privacyPolicy"
        type="checkbox"
        class="form-checkbox mr-2"
        v-model="currentStepPath.privacyPolicy"
      />Ich habe die
      <a
        :href="`//${tradeInStore.setting.website}${tradeInStore.setting.dataProtectionUrl}`"
        target="_blank"
        class="text-brand underline font-semibold"
        >Datenschutzerklärung</a
      >
      gelesen und verstanden. *</label
    >
    <p class="text-xs my-4">* - Pflichtfelder</p>
  </div>
  <div class="flex justify-end py-4">
    <button
      @click="tradeInStore.goBack()"
      class="flex items-center px-2 py-1 text-gray-600 text-xs font-light hover:underline"
    ></button>
    <button
      class="ml-4 flex items-center border rounded shadow px-2 py-1"
      :class="[
        isButtonDisabled
          ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
          : 'bg-brand text-white font-semibold shadow-md hover:bg-brand-darker'
      ]"
      @click="sendRequest"
      :disabled="isButtonDisabled"
    >
      Anfrage senden
    </button>
  </div>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { useTradeInStore } from '../../tradeInStore.js'

import { useRequestStore } from '../../requestStore.js'
const requestStore = useRequestStore()
const tradeInStore = useTradeInStore()

const displayedAccordion = computed(() => {
  return tradeInStore.activeTab === 'hersteller'
    ? tradeInStore.accordion
    : tradeInStore.activeTab === 'vin'
      ? tradeInStore.vinAccordion
      : tradeInStore.hsnTsnaccordion
})

const currentStepPath = computed(() => {
  return displayedAccordion.value[tradeInStore.currentExpandedKey].steps[
    tradeInStore.currentStepKey
  ].selectedProposal
})

function sendRequest() {
  requestStore.transformData(tradeInStore)
  requestStore.fetchRequestData()
  // requestStore.responseDataChecke = true

  // Add any additional logic to send the request here
}
const isButtonDisabled = computed(() => {
  const emailRequired = tradeInStore.isEmailRequired
  const phoneRequired = tradeInStore.isPhoneRequired

  return (
    !currentStepPath.value ||
    !currentStepPath.value.privacyPolicy ||
    !currentStepPath.value.firstName ||
    !currentStepPath.value.lastName ||
    (emailRequired && !currentStepPath.value.email) ||
    (phoneRequired && !currentStepPath.value.phone)
  )
})
onMounted(async () => {
  let currentStep =
    displayedAccordion.value[tradeInStore.currentExpandedKey].steps[tradeInStore.currentStepKey]
  currentStep.visited = true
})
</script>

<style scoped>
@import '../../index.css';
</style>
