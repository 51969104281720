<template>
  <div class="flex flex-col items-center text-center p-8">
    <FontAwesomeIcon icon="fa-regular fa-circle-check" style="height: 240px; color: #36ff04" />
    <p class="text-xl mt-8">
      Die Anfrage wurde erfolgreich an uns gesendet. Wir werden uns in wenigen Minuten mit unserem
      Angebot an Sie bei Ihnen melden.<br />Unser vorläufiges Angebot beträgt
      <span class="text-brand font-semibold">{{ purchasePrice }}€</span>
    </p>
  </div>
</template>

<script setup>
import { useRequestStore } from '../../requestStore.js'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
const requestStore = useRequestStore()

const purchasePrice = requestStore.responseData.purchasePriceGrossRounded
</script>
