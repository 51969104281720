<template>
  <div class="container mb-4 pb-4">
    <div v-if="requestStore.responseDataChecke">
      <requestDone />
    </div>
    <div v-else-if="tradeInStore.error">
      <div class="bg-red-200 p-4 text-red-800 antialiased">
        <h1 class="font-bold text-4xl">Systemfehler</h1>
        <pre class="mt-4 pt-4 text-sm leading-none"
          >{{ tradeInStore.error.message }} {{ tradeInStore.error.detail }}</pre
        >
      </div>
    </div>
    <div v-else-if="requestStore.requestError || tradeInStore.inputError">
      <errorScreen />
    </div>
    <div v-else-if="requestStore.loading">
      <loadingScreen />
    </div>
    <div v-else>
      <div class="flex border-b-4 border-brand">
        <button
          v-if="tradeInStore.showSelection"
          @click="tradeInStore.activeTab = 'hersteller'"
          :class="[
            'p-4 md:p-6 border-r border-t first:border-l font-semibold text-base focus:outline-none',
            tradeInStore.activeTab === 'hersteller'
              ? 'bg-brand text-white'
              : 'text-gray-500 border-gray-400'
          ]"
        >
          Hersteller/Modell
        </button>
        <button
          v-if="tradeInStore.showVin"
          @click="tradeInStore.activeTab = 'vin'"
          :class="[
            'p-4 md:p-6 border-r border-t first:border-l font-semibold text-base focus:outline-none',
            tradeInStore.activeTab === 'vin'
              ? 'bg-brand text-white'
              : 'text-gray-500 border-gray-400'
          ]"
        >
          FIN
        </button>
        <button
          v-if="tradeInStore.showKba"
          @click="tradeInStore.activeTab = 'hsnTsn'"
          :class="[
            'p-4 md:p-6 border-r border-t first:border-l font-semibold text-base focus:outline-none',
            tradeInStore.activeTab === 'hsnTsn'
              ? 'bg-brand text-white'
              : 'text-gray-500 border-gray-400'
          ]"
        >
          HSN/TSN
        </button>
      </div>
      <transition-group name="accordion-transition">
        <div
          class="accordion"
          v-for="(accordionStep, index) in tradeInStore.getDisplayedAccordion()"
          :key="index"
        >
          <div class="accordion-header flex items-center border border-t-0 px-4 py-2">
            <span class="w-6 mx-4 font-semibold text-3xl md:text-4xl text-center">{{
              Object.keys(tradeInStore.getDisplayedAccordion()).indexOf(index) + 1
            }}</span>
            <div>
              <h3 class="text-xl text-gray-800">{{ accordionStep.title }}</h3>
              <div class="text-sm leading-tight font-light text-gray-600">
                {{
                  tradeInStore.displaySelected(accordionStep.content)
                    ? tradeInStore.displaySelected(accordionStep.content)
                    : ''
                }}
              </div>
            </div>

            <div class="ml-auto flex gap-8 text-gray-400">
              <FontAwesomeIcon
                icon="fa-solid fa-pencil"
                class="accordion-icon my-2"
                v-if="tradeInStore.currentExpandedKey === index"
              />

              <FontAwesomeIcon
                icon="fa-regular fa-circle-check"
                class="accordion-icon my-2"
                style="color: #36ff04"
                v-if="
                  Object.values(tradeInStore.getDisplayedAccordion()[index].steps).every(
                    (step) => step.selected
                  )
                "
              />

              <FontAwesomeIcon
                icon="fa-solid fa-chevron-up"
                class="accordion-icon my-2"
                v-if="
                  tradeInStore.currentExpandedKey === index ||
                  tradeInStore.previousAccordionKey === index ||
                  Object.values(tradeInStore.getDisplayedAccordion()[index].steps).some(
                    (step) => step.selected
                  ) ||
                  Object.values(tradeInStore.getDisplayedAccordion()[index].steps).some(
                    (step) => step.visited
                  )
                "
                :class="{ 'rotate-180': tradeInStore.currentExpandedKey === index }"
                @click="tradeInStore.toggleAccordion(index)"
              />
            </div>
          </div>
          <div v-if="tradeInStore.currentExpandedKey === index" class="animate-fade-in">
            <div
              class="accordion-content px-4 md:px-8 py-4 bg-gray-200 shadow-inner"
              :class="{ expanded: tradeInStore.currentExpandedKey === index }"
            >
              <component v-if="!tradeInStore.loading" :is="components[accordionStep.content]" />

              <loadingScreen v-else></loadingScreen>
            </div>
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script setup>
import { onMounted, useAttrs, watch } from 'vue'
import { useTradeInStore } from '../tradeInStore.js'
import { useRequestStore } from '../requestStore.js'
import requestDone from './utils/requestDone.vue'
import manufacturer from './Panel/manufacturer.vue'
import vehicleDetail from './Panel/vehicleDetail.vue'
import equipment from './Panel/Equipment.vue'
import carRegistration from './Panel/CarRegistration.vue'
import damage from './Panel/Damage.vue'
import priceRequest from './Panel/PriceRequest.vue'
import submit from './Panel/Submit.vue'
import loadingScreen from './utils/loadingScreen.vue'
import hsnTsn from './Panel/hsnTsn.vue'
import vin from './Panel/vin.vue'
import errorScreen from './utils/errorScreen.vue'
import { cloneDeep } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const components = {
  manufacturer,
  vehicleDetail,
  hsnTsn,
  vin,
  equipment,
  carRegistration,
  damage,
  priceRequest,
  submit
}

const tradeInStore = useTradeInStore()
const requestStore = useRequestStore()
const attrs = useAttrs()
watch(
  () => tradeInStore.activeTab,
  (newTab) => {
    tradeInStore.resetTradeInStore()
    tradeInStore.activeTab = newTab
    if (newTab === 'hersteller') {
      if (attrs.hasOwnProperty('tiwManufacturer')) {
        tradeInStore.currentExpandedKey = 'accordion1'
        tradeInStore.currentStepKey = 'mainTypeGroupFb'
      } else {
        tradeInStore.currentExpandedKey = 'accordion1'
        tradeInStore.currentStepKey = 'manufacturer'
      }
    } else if (newTab === 'hsnTsn') {
      tradeInStore.currentExpandedKey = 'accordion1'
      tradeInStore.currentStepKey = 'hsnTsn'
    } else {
      tradeInStore.currentExpandedKey = 'accordion1'
      tradeInStore.currentStepKey = 'vin'
    }
  }
)

onMounted(async () => {
  tradeInStore.activeTab = 'hersteller'
  await tradeInStore.fetchTradeInData()
  tradeInStore.initialState = cloneDeep(tradeInStore)
})
</script>

<style>
@import '../index.css';
</style>
