<template>
  <div class="px-4 md:px-8 py-4 animate-fade-in">
    <form @submit.prevent="submitVin">
      <div class="flex space-x-4">
        <label class="block flex-1" id="input-hsn"
          ><span class="text-sm font-semibold">FIN</span
          ><input
            type="text"
            class="form-input w-full mt-1"
            maxlength="17"
            v-model="currentStepPath.selectedProposal.vin"
        /></label>
      </div>
      <div class="text-sm font-semibold text-audaris-red" v-if="tradeInStore.finError">
        {{ tradeInStore.finError }}
      </div>
      <div class="my-2 font-bold text-green-500" v-if="tradeInStore.selectedVehicle.containerName">
        Fahrzeug identifiziert: {{ tradeInStore.selectedVehicle.containerName }}
      </div>
      <div v-else class="my-2">
        <button
          type="button"
          class="flex items-center gap-2 font-semibold text-brand hover:underline"
          @click="isCollapsibleOpen = !isCollapsibleOpen"
        >
          <FontAwesomeIcon
            icon="fa-solid fa-chevron-up"
            class="my-2"
            style="height: 18px"
            :class="{ 'rotate-180': isCollapsibleOpen }"
          />

          FIN: Was ist das und Wo ist sie zu finden?
        </button>
        <div
          id="collapsible-hsn-tsn"
          v-show="isCollapsibleOpen"
          class="overflow-hidden animate-fade-in"
        >
          <div class="py-4 md:grid flex flex-wrap gap-2 items-start">
            <div class="grow basis-72 mb-4">
              <p>
                Die Fahrzeug-Identifikationsnummer (FIN), auf Englisch als „vehicle identification
                number (VIN)“ bekannt, ist die 17-stellige Seriennummer, über die jedes hergestellte
                Kraftfahrzeug verfügt. Damit lässt sich das Fahrzeug u. a. hinsichtlich des
                Herstellers, Modelljahrs oder Baujahr eindeutig identifizieren.
              </p>
              <p class="mt-2 text-xs italic">Quelle: ADAC</p>
              <div class="py-4 md:grid gap-2 grid-cols-2 items-start">
                <figure class="mt-4 md:mt-0">
                  <img src="@/assets/vin.jpg" class="w-full object-contain" />
                  <figcaption class="text-sm font-bold text-gray-600">
                    In der Zulassungsbescheinigung Teil 1
                  </figcaption>
                </figure>
                <figure class="mt-4 md:mt-0">
                  <img src="@/assets/vin-body.jpg" class="w-full object-contain" />
                  <figcaption class="text-sm font-bold text-gray-600">An der Karosserie</figcaption>
                </figure>
                <figure class="mt-4 md:mt-0">
                  <img src="@/assets/vin-windshield.jpg" class="w-full object-contain" />
                  <figcaption class="text-sm font-bold text-gray-600">
                    Hinter der Windshutzscheibe
                  </figcaption>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        type="submit"
        class="flex items-center border rounded shadow px-2 py-1 bg-brand text-white font-semibold text-xl px-4 shadow-md hover:bg-brand-darker disabled:opacity-50 disabled:pointer-events-none"
      >
        Suchen
      </button>
    </form>
  </div>
</template>

<script setup>
import { useTradeInStore } from '../../tradeInStore'
import { computed, onMounted, ref } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
const tradeInStore = useTradeInStore()

const vin = ref('')
const isCollapsibleOpen = ref(false)

const displayedAccordion = computed(() => {
  return tradeInStore.activeTab === 'hersteller'
    ? tradeInStore.accordion
    : tradeInStore.activeTab === 'vin'
      ? tradeInStore.vinAccordion
      : tradeInStore.hsnTsnaccordion
})
const currentStepPath = computed(() => {
  return displayedAccordion.value[tradeInStore.currentExpandedKey].steps[
    tradeInStore.currentStepKey
  ]
})
const submitVin = () => {
  const option =
    displayedAccordion.value[tradeInStore.currentExpandedKey].steps[tradeInStore.currentStepKey]
      .selectedProposal
  tradeInStore.selectFilterProposal(option, 'vin', 'accordion1')
}
onMounted(async () => {
  let currentStep =
    displayedAccordion.value[tradeInStore.currentExpandedKey].steps[tradeInStore.currentStepKey]
  if (!currentStep.selectedProposal) {
    currentStep.selectedProposal = {
      vin: ''
    }
  }
  currentStep.visited = true
})
</script>

<style scoped>
@import '../../index.css';
</style>

<!-- FETCH LINK HSN TSN https://tradein.cloud.audaris.icu/-/api?clientId=400&kba=7909%2FADG -->
