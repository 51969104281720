<template>
  <label class="block" v-if="tradeInStore.vehicles.length > 1">
    <span class="text-sm font-semibold">Fahzeug wählen</span>
    <select class="form-input w-full mt-1" v-model="tradeInStore.selectedVehicle">
      <option v-for="(vehicle, index) in tradeInStore.vehicles" :key="index" :value="vehicle">
        {{ vehicle.containerName }}
      </option>
    </select>
  </label>
  <div class="flex mb-4 justify-between items-center animate-fade-in">
    <label class="w-full">
      <span class="text-sm font-semibold"
        >{{ currentStepPath.title }} filtern ({{ availableOptionsCount }}/{{
          totalOptionsCount
        }})</span
      >
      <input type="text" class="form-input w-full mt-1" v-model="searchText" />
    </label>
  </div>
  <div class="equipment-content grid sm:grid-cols-2 cols-gap-2 mt-2">
    <div v-for="(option, index) in filteredProposals" :key="index" class="flex mb-1">
      <input
        :id="`equipment-${index}`"
        type="checkbox"
        :value="option"
        :checked="
          currentStepPath.selectedProposal &&
          currentStepPath.selectedProposal[option.av] !== undefined
        "
        @change="tradeInStore.updateEquipmentMatrix($event, option)"
        class="form-checkbox"
      />
      <label
        :for="`equipment-${index}`"
        class="ms-1 font-small text-sm text-gray-900 dark:text-gray-300"
        style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap"
        >{{ option.name }}</label
      >
    </div>
  </div>
  <div class="flex justify-end py-4">
    <button
      @click="tradeInStore.goBack()"
      class="flex items-center px-2 py-1 text-gray-600 text-xs font-light hover:underline"
    >
      <svg class="arrow-icons mx-2">
        <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
      </svg>
      Zurück
    </button>
    <button
      @click="tradeInStore.nextSelection()"
      class="ml-4 flex items-center border rounded shadow px-2 py-1 bg-brand text-white font-semibold shadow-md hover:bg-brand-darker"
    >
      Weiter
    </button>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { useTradeInStore } from '../../tradeInStore.js'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const tradeInStore = useTradeInStore()

let searchText = ref('')

const displayedAccordion = computed(() => {
  return tradeInStore.activeTab === 'hersteller'
    ? tradeInStore.accordion
    : tradeInStore.activeTab === 'vin'
      ? tradeInStore.vinAccordion
      : tradeInStore.hsnTsnaccordion
})

const currentStepPath = computed(() => {
  return displayedAccordion.value[tradeInStore.currentExpandedKey].steps[
    tradeInStore.currentStepKey
  ]
})

const filteredProposals = computed(() => {
  const proposals = tradeInStore.filterEquipmentProposals()
  if (searchText.value) {
    return proposals.filter((proposal) =>
      proposal.name.toLowerCase().includes(searchText.value.toLowerCase())
    )
  }
  return proposals
})
const totalOptionsCount = computed(() => {
  return tradeInStore.filterEquipmentProposals().length
})
const availableOptionsCount = computed(() => filteredProposals.value.length)

onMounted(async () => {
  let currentStep =
    displayedAccordion.value[tradeInStore.currentExpandedKey].steps[tradeInStore.currentStepKey]
  tradeInStore.selectedVehicle = tradeInStore.vehicles[0]
  // console.log(JSON.stringify(tradeInStore.selectedVehicle, null, 2))
  currentStep.visited = true
})
</script>
<style scoped>
@import '../../index.css';
</style>
