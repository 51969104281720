import { createApp, h, useAttrs, useSlots } from 'vue'
import { createPinia } from 'pinia'
import { defineCustomElement } from 'vue'
import tradeInWidget from './components/tradein-widget.ce.vue'
import TradeinButton from './components/tradein-widget-button.ce.vue'
import indexCss from './index.css?inline'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faChevronUp,
  faPencil,
  faArrowRight,
  faArrowLeft,
  faCircleExclamation
} from '@fortawesome/free-solid-svg-icons'
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { useTradeInStore } from './tradeInStore'

import router from './router'

const app = createApp({})
library.add(faChevronUp, faPencil, faCircleCheck, faArrowRight, faArrowLeft, faCircleExclamation)
const pinia = createPinia()

app.use(pinia)
app.use(router)
app.component('FontAwesomeIcon', FontAwesomeIcon)

const tradeInStore = useTradeInStore()
const TradeInWidgetElement = defineCustomElement({
  setup() {
    const attrs = useAttrs()

    const clientId = attrs.clientId

    const layout = attrs.layout

    const apiEndpoint = `https://tradein.cloud.audaris.icu/-/api`

    async function initialFetch() {
      tradeInStore.setAttributesFromProps(attrs)

      if (!clientId) return
      const url = new URL(apiEndpoint)
      url.searchParams.set('clientId', clientId)
      try {
        const response = await fetch(url)
        if (!response.ok) {
          const error = new Error(`Error ${response.status}`)
          error.statusCode = response.status
          error.url = url.href // add the URL to the error object
          throw error
        }
        const data = await response.json()
        const settings = data.settings
        const colors = settings.colors

        document.documentElement.style.setProperty('--color-brand', colors.brand)
        document.documentElement.style.setProperty('--color-brand-darker', colors['brand-darker'])
      } catch (error) {
        console.error(error)
      } finally {
      }
    }
    initialFetch()
  },
  render() {
    return h(tradeInWidget)
  },
  mounted() {
    const styleElement = document.createElement('style')
    const layoutElement = document.createElement('layout')
    styleElement.textContent = indexCss.toString()
    this.$el.appendChild(styleElement)
    this.$el.appendChild(layoutElement)
  }
})

customElements.define('tradein-widget', TradeInWidgetElement)

const TradeInWidgetButtonElement = defineCustomElement({
  setup() {
    const attrs = useAttrs()
    const slots = useSlots()

    const clientId = attrs.clientId

    const apiEndpoint = `https://tradein.cloud.audaris.icu/-/api`

    async function initialFetch() {
      tradeInStore.setAttributesFromProps(attrs)

      if (!clientId) return
      const url = new URL(apiEndpoint)
      url.searchParams.set('clientId', clientId)
      try {
        const response = await fetch(url)
        if (!response.ok) {
          const error = new Error(`Error ${response.status}`)
          error.statusCode = response.status
          error.url = url.href // add the URL to the error object
          throw error
        }
        const data = await response.json()
        const settings = data.settings
        const colors = settings.colors

        document.documentElement.style.setProperty('--color-brand', colors.brand)
        document.documentElement.style.setProperty('--color-brand-darker', colors['brand-darker'])
      } catch (error) {
        console.error(error)
      } finally {
      }
    }
    initialFetch()
    return { slots }
  },
  render() {
    return h(TradeinButton, null, this.slots)
  },
  mounted() {
    const styleElement = document.createElement('style')
    const layoutElement = document.createElement('layout')
    styleElement.textContent = indexCss.toString()
    this.$el.appendChild(styleElement)
    this.$el.appendChild(layoutElement)
  }
})

customElements.define('tradein-widget-button', TradeInWidgetButtonElement)
