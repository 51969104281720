<template class="vehicelDetail-content">
  <div class="flex mb-4 justify-between items-center">
    <label>
      <span class="text-sm font-semibold">{{ currentStepPath.title }} suchen</span>
      <input type="text" class="form-input w-full mt-1" v-model="searchText" />
    </label>
    <div class="ml-auto mt-4 flex flex-row gap-x-2">
      <button
        @click="tradeInStore.goBack()"
        class="flex items-center border rounded shadow px-2 py-1 bg-white hover:bg-gray-200"
      >
        <svg class="arrow-icons">
          <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
        </svg>
      </button>
      <button
        @click="tradeInStore.goForward()"
        class="flex items-center border rounded shadow px-2 py-1 bg-white hover:bg-gray-200"
      >
        <svg class="arrow-icons">
          <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
        </svg>
      </button>
    </div>
  </div>
  <div class="text-sm font-semibold text-red-600" v-if="tradeInStore.vehicleSelectionError">
    {{ tradeInStore.vehicleSelectionError }}
  </div>
  <div class="flex mb-4 justify-between items-center">
    <div v-if="currentStepPath" class="flex flex-wrap gap-2 opacity-0 animate-fade-in">
      <label
        v-for="(option, index) in filteredProposals"
        :key="`${currentStepPath}-${index}`"
        :class="[
          'flex flex-col items-center px-2 py-1 justify-center shadow w-fit h-full border border-gray-300 rounded cursor-pointer',
          currentStepPath.selectedProposal.k === option.k
            ? 'bg-brand text-white'
            : tradeInStore.vehicleSelectionError
              ? 'text-audaris-red'
              : 'bg-white hover:bg-gray-200'
        ]"
        @click.self="
          tradeInStore.selectFilterProposal(
            option,
            tradeInStore.currentStepKey,
            tradeInStore.currentExpandedKey
          )
        "
      >
        <input
          type="radio"
          :id="`${currentStepPath}-${index}`"
          :value="option"
          v-model="currentStepPath.selectedProposal"
          class="hidden"
        />
        {{ option.v }}
      </label>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useTradeInStore } from '../../tradeInStore.js'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const tradeInStore = useTradeInStore()

let searchText = ref('')

const currentStepPath = computed(() => {
  return tradeInStore.accordion[tradeInStore.currentExpandedKey].steps[tradeInStore.currentStepKey]
})

const filteredProposals = computed(() => {
  const proposals = currentStepPath.value.proposals
  if (!proposals) {
    return []
  }
  console.log(proposals)

  if (searchText.value) {
    return proposals.filter((proposal) => proposal.v.toLowerCase().includes(searchText.value))
  }
  return proposals
})
</script>
