<template>
  <div v-if="tradeInStore.currentStepKey === 'price'">
    <div class="md:flex items-start">
      <label class="block w-full md:w-auto mt-4 px-2"
        ><span class="text-sm font-semibold">Verkauf von</span
        ><select class="form-select max-w-full mt-1" v-model="currentStepPath.saleType">
          <option value="PRIVATE">Privater Verkauf</option>
          <option value="COMERCIAL">Gewerblicher Verkauf</option>
        </select></label
      >
      <label
        v-show="currentStepPath.saleType === 'COMERCIAL'"
        class="block w-full md:w-auto mt-4 px-2"
        ><span class="text-sm font-semibold">Mehrwertsteuer</span
        ><select class="form-select max-w-full mt-1" v-model="currentStepPath.mwSt">
          <option value="true">MwSt. ausweisbar</option>
          <option value="false">MwSt. nicht ausweisbar</option>
        </select></label
      >
    </div>
    <label class="block w-full md:w-1/2 mt-4 px-2"
      ><span class="text-sm font-semibold">Mindestpreis (Euro) *</span
      ><input type="number" class="form-input w-full mt-1" v-model="currentStepPath.priceRequest"
    /></label>
  </div>

  <div v-else-if="currentStepPath" class="flex flex-wrap my-2 gap-2">
    <label
      for="notFinanced"
      :class="{
        'bg-white hover:bg-gray-200': currentStepPath.financing,
        'bg-brand text-white': !currentStepPath.financing
      }"
      class="flex flex-col items-center px-2 py-1 justify-center shadow w-fit h-full border border-gray-300 rounded cursor-pointer"
    >
      <input
        type="radio"
        id="notFinanced"
        v-model="currentStepPath.financing"
        :value="false"
        class="hidden"
      />
      Keine Finanzierung
    </label>
    <label
      for="financed"
      :class="{
        'bg-white hover:bg-gray-200': !currentStepPath.financing,
        'bg-brand text-white': currentStepPath.financing
      }"
      class="flex flex-col items-center px-2 py-1 justify-center shadow w-fit h-full border border-gray-300 rounded cursor-pointer"
    >
      <input
        type="radio"
        id="financed"
        v-model="currentStepPath.financing"
        :value="true"
        class="hidden"
      />
      Fahrzeug wurde finanziert
    </label>

    <div v-if="currentStepPath.financing" class="flex flex-wrap gap-x-2 gap-y-2">
      <h3 class="mt-4 my-2 mx-2 text-sm font-semibold text-gray-900">
        Liegt der KFZ-Brief (Zulassungsbescheinigung Teil 2) bei der Bank?
      </h3>
      <div class="flex flex-wrap my-2 gap-2">
        <label
          for="onMe"
          :class="{
            'bg-white hover:bg-gray-200': currentStepPath.vehicleLetterAtBank,
            'bg-brand text-white': !currentStepPath.vehicleLetterAtBank
          }"
          class="flex flex-col items-center px-2 py-1 justify-center shadow w-fit h-fit border border-gray-300 rounded cursor-pointer"
        >
          <input
            type="radio"
            id="onMe"
            v-model="currentStepPath.vehicleLetterAtBank"
            :value="false"
            class="hidden"
          />
          KFZ-Brief liegt bei mir
        </label>
        <label
          for="bank"
          :class="{
            'bg-white hover:bg-gray-200': !currentStepPath.vehicleLetterAtBank,
            'bg-brand text-white': currentStepPath.vehicleLetterAtBank
          }"
          class="flex flex-col items-center px-2 py-1 justify-center shadow w-fit h-fit border border-gray-300 rounded cursor-pointer"
        >
          <input
            type="radio"
            id="bank"
            v-model="currentStepPath.vehicleLetterAtBank"
            :value="true"
            class="hidden"
          />
          KFZ-Brief liegt bei der Bank
        </label>
      </div>
    </div>
  </div>
  <div class="flex justify-end py-4">
    <button
      @click="tradeInStore.goBack()"
      class="flex items-center px-2 py-1 text-gray-600 text-xs font-light hover:underline"
    >
      <svg class="arrow-icons mx-2">
        <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
      </svg>
      Zurück
    </button>
    <button
      @click="tradeInStore.nextSelection()"
      :disabled="tradeInStore.currentStepKey === 'price' && !currentStepPath.priceRequest"
      :class="[
        tradeInStore.currentStepKey === 'price' && !currentStepPath.priceRequest
          ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
          : 'bg-brand text-white font-semibold shadow-md hover:bg-brand-darker'
      ]"
      class="ml-4 flex items-center border rounded shadow px-2 py-1"
    >
      Weiter
    </button>
  </div>
</template>

<script setup>
import { computed, onMounted, watch } from 'vue'
import { useTradeInStore } from '../../tradeInStore.js'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const tradeInStore = useTradeInStore()
const displayedAccordion = computed(() => {
  return tradeInStore.activeTab === 'hersteller'
    ? tradeInStore.accordion
    : tradeInStore.activeTab === 'vin'
      ? tradeInStore.vinAccordion
      : tradeInStore.hsnTsnaccordion
})
let currentStepPath = computed(() => {
  return displayedAccordion.value[tradeInStore.currentExpandedKey].steps[
    tradeInStore.currentStepKey
  ].selectedProposal
})
watch(
  currentStepPath,
  (newValue, oldValue) => {
    if (newValue.saleType === 'PRIVATE') {
      newValue.mwSt = false
    }
    if (newValue.financing === false) {
      newValue.vehicleLetterAtBank = false
    }
  },
  { deep: true }
)
onMounted(async () => {
  let currentStep =
    displayedAccordion.value[tradeInStore.currentExpandedKey].steps[tradeInStore.currentStepKey]
  currentStep.selectedProposal.saleType = 'PRIVATE'
  currentStep.visited = true
  // console.log(currentStep)
})
</script>

<style scoped>
@import '../../index.css';
</style>
